<template>
    <Transition
        :name="transitionName"
        :duration="{ enter: transitionEnter, transition: transitionLeave }">
        <div
            id="toast-default"
            :class="`fixed flex h-[46px] w-full items-center rounded-lg bg-white px-[23px] py-[14px] text-black shadow-xl lg:w-auto lg:pr-[53px]  ${ToastPosition} ${zIndex}`"
            role="alert"
            v-if="show">
            <div class="flex h-[20px] w-[20px] items-center justify-center">
                <component is="icon" :class="modifier">
                    <font-awesome-icon :icon="icon" class="h-[20px] w-[20px]" />
                </component>
            </div>

            <div class="ml-[12px] text-[14px] font-normal">
                <slot v-if="text === ''"></slot>
                <div v-else v-html="text"></div>
                <span
                    class="text-black sm:cursor-pointer"
                    @click="textAction"
                    v-show="actionText != ''"
                    >{{ ' ' }}{{ actionText }}</span
                >
            </div>
            <button
                type="button"
                class="absolute right-[23px] top-[16px] inline-flex h-[20px] w-[20px] rounded-lg bg-transparent text-black hover:text-graycontrol"
                aria-label="Close"
                @click="closeButton">
                <font-awesome-icon
                    icon="fa-regular fa-xmark"
                    class="h-[17px] w-[17px] text-graycontrol sm:hover:text-black" />
            </button>
        </div>
    </Transition>
</template>
<script setup>
    import { computed } from 'vue'

    const props = defineProps({
        icon: {
            type: String,
            default: 'fa-solid fa-circle-info',
        },
        modifier: {
            type: String,
            default: 'text-black bg-transparent m-auto',
        },
        iconColor: {
            type: String,
            default: 'text-black',
        },
        iconBgColor: {
            type: String,
            default: 'bg-transparent',
        },
        text: {
            type: String,
            default: '',
        },
        actionText: {
            type: String,
            default: '',
        },
        transitionEnter: {
            type: Number,
            default: 500,
        },
        transitionLeave: {
            type: Number,
            default: 500,
        },
        transitionName: {
            type: String,
            default: 'fade',
            validator(name) {
                return ['fade', 'slide-fade', 'nested'].includes(name)
            },
        },
        show: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: 'topRight',
        },
        zIndex: {
            type: String,
            default: 'z-50',
        },
    })

    const emit = defineEmits(['closeButton', 'textAction'])

    const closeButton = () => {
        emit('closeButton')
    }

    const textAction = () => {
        emit('textAction')
    }

    const ToastPosition = computed(() => {
        return {
            topLeft: 'top-10 left-0',
            topRight: 'top-10 right-0',
            bottomLeft: 'bottom-10 left-0',
            bottomRight: 'bottom-10 right-0',
        }[props.position]
    })
</script>
<style>
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(20px);
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .nested-enter-active .inner,
    .nested-leave-active .inner {
        transition: all 0.3s ease-in-out;
    }

    .nested-enter-from .inner,
    .nested-leave-to .inner {
        transform: translateX(30px);
        opacity: 0;
    }

    .nested-enter-active .inner {
        transition-delay: 0.25s;
    }
</style>
